import { Crumb } from "@monorepo/types";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
// import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

interface Props {
  title?: string;
  crumbs?: Crumb[];
  children?: ReactNode;
}

const Breadcrumbs = ({ title, crumbs, children = <></> }: Props) => {
  return (
    <></>
    // <Row>
    //   <Col xs="12">
    //     <div className="page-title-box d-sm-flex align-items-center justify-content-between">
    //       <h1 className="mb-0 font-size-32">{title}</h1>

    //       <div className="page-title-right d-sm-flex align-items-center justify-content-between">
    //         {children}
    //         <Breadcrumb listClassName="m-0">
    //           {crumbs?.map((crumb, index) => (
    //             <BreadcrumbItem
    //               active={index === crumbs.length}
    //               key={`breadcrumb-${index}`}
    //             >
    //               {crumb.link ? (
    //                 <Link to={crumb.link}>{crumb.title}</Link>
    //               ) : (
    //                 crumb.title
    //               )}
    //             </BreadcrumbItem>
    //           ))}
    //         </Breadcrumb>
    //       </div>
    //     </div>
    //   </Col>
    // </Row>
  );
};

export default Breadcrumbs;

import { withWrapper } from "app/hooks";
// import { Container } from "reactstrap";

const Privacy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>
        MemoAI (Company, we, us, or our) is committed to protecting the privacy
        and security of the personal information of its users (Users, you, or
        your) who access our website at <a href=".">URL</a> (Site). This Privacy
        Policy describes how we collect, use, and disclose your personal
        information when you use our Site and the choices you have associated
        with that information.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We may collect personal information from you when you visit our Site,
        register on the Site, fill out a form, or engage in other activities on
        our Site. The personal information we collect may include your name,
        email address, mailing address, phone number, date of birth, medical
        history, health information, and other information you provide to us.
      </p>

      <h2>How We Use Your Information</h2>
      <p>We may use your personal information for the following purposes:</p>
      <ul>
        <li>To provide you with access to our Site and its services.</li>
        <li>
          To personalize your experience and improve our Site and services.
        </li>
        <li>
          To communicate with you about our services, including sending you
          emails or other electronic communications.
        </li>
        <li>To process transactions or payments for our services.</li>
        <li>To comply with legal or regulatory requirements.</li>
        <li>To protect our rights or interests, or those of others.</li>
      </ul>

      <h2>How We Protect Your Information</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or electronic storage is 100% secure.
        Therefore, we cannot guarantee absolute security of your personal
        information.
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        We do not sell, trade, or rent your personal information to others. We
        may share your personal information with our third-party service
        providers who assist us in providing our services, such as hosting
        providers, payment processors, or customer service providers. These
        third-party service providers are required to protect your personal
        information and are not authorized to use or disclose your personal
        information for any other purpose.
      </p>

      <p>
        We may also share your personal information with third parties if we
        believe it is necessary to comply with legal or regulatory requirements,
        or to protect our rights or interests, or those of others.
      </p>

      <h2>Third-Party Links</h2>
      <p>
        Our Site may contain links to third-party websites, products, or
        services. These third-party websites have separate and independent
        privacy policies. We have no control over, and assume no responsibility
        for, the content, privacy policies, or practices of any third-party
        websites, products, or services.
      </p>

      <h2>Your Rights and Choices</h2>
      <p>
        You have certain rights and choices regarding your personal information,
        including the right to access, correct, or delete your personal
        information. You may also have the right to object to or restrict the
        processing of your personal information, or to request a copy of your
        personal information. To exercise any of these rights or choices, please
        contact us at [INSERT CONTACT INFORMATION].
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We reserve the right to modify this Privacy Policy at any time. Any
        changes to this Privacy Policy will be posted on our Site and will
        become effective immediately upon posting. We encourage you to review
        this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at [INSERT CONTACT INFORMATION].
      </p>
    </>
  );
};

export default withWrapper(Privacy);

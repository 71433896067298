import { withWrapper } from "app/hooks";
// import { Container } from "reactstrap";

const Home = () => {
  return (
    // <Container fluid>
    <h3>Home</h3>
    // </Container>
  );
};

export default withWrapper(Home);

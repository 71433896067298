import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function StickyFooter() {
  return (
    <Box component="footer" sx={{ py: 3, mt: "auto" }}>
      <Container maxWidth="sm" sx={{ textAlign: "center" }}>
        {new Date().getFullYear()} © MemoAI LTD.
      </Container>
    </Box>
  );
}

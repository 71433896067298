import { withWrapper } from "app/hooks";

const Terms = () => {
  return (
    // <Container fluid>
    <h3>Terms</h3>
    // </Container>
  );
};

export default withWrapper(Terms);

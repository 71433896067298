import { configureStore } from "@reduxjs/toolkit";
import { brainDataMiddleware } from "./middleware";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(brainDataMiddleware)
});

window.globalStore = store;

export default store;

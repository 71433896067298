import { useAppDispatch, useAppSelector } from "app/hooks";
import { routesGetter, startupScripts } from "modules";
import { selectIsLoggedIn } from "modules/authentication";
import { SpinnerLoader } from "modules/common";
import { pusherEvents } from "modules/pusher";
import { Suspense, useEffect, useMemo } from "react";
import { useRoutes } from "react-router-dom";

const Page = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    pusherEvents.init();
    return () => {
      pusherEvents.destroy();
    };
  }, []);

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    dispatch(startupScripts.load(isLoggedIn));
  }, [isLoggedIn]);

  const routes = useMemo(() => routesGetter.get(isLoggedIn), [isLoggedIn]);
  return useRoutes(routes);
};

const Loader = () => (
  <div id="preloader">
    <div id="status">
      <SpinnerLoader />
    </div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
};

export default App;

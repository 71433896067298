import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { AuthenticationService, UserEntity } from "openapi";
import { RootState } from "types/store";

const { usersControllerFindAll } = AuthenticationService;

// Async thunks
export const findAllUsersAction = createAsyncThunk(
  "users/findAll",
  async () => await usersControllerFindAll()
);

// Entity adapter
const entityAdapter = createEntityAdapter<UserEntity>({
  sortComparer: (entityA, entityB) => entityA.name.localeCompare(entityB.name)
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "usersEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllUsersAction.fulfilled, entityAdapter.setAll);
  }
});

export const usersEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectUsers,
  selectEntities: selectUsersEntities,
  selectById: selectUserById
} = entityAdapter.getSelectors((state: RootState) => state.usersEntities);

// Custom selector

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { LoginUserRequestDto } from '../models/LoginUserRequestDto';
import type { LoginUserResponseDto } from '../models/LoginUserResponseDto';
import type { MyUserDto } from '../models/MyUserDto';
import type { SignupUserRequestDto } from '../models/SignupUserRequestDto';
import type { SignupUserResponseDto } from '../models/SignupUserResponseDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserEntity } from '../models/UserEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthenticationService {
    /**
     * @param requestBody
     * @returns SignupUserResponseDto
     * @returns any
     * @throws ApiError
     */
    public static authenticationControllerSignup(
        requestBody: SignupUserRequestDto,
    ): CancelablePromise<SignupUserResponseDto | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authentication/signUp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns LoginUserResponseDto
     * @returns any
     * @throws ApiError
     */
    public static authenticationControllerLogin(
        requestBody: LoginUserRequestDto,
    ): CancelablePromise<LoginUserResponseDto | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authentication/logIn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MyUserDto
     * @returns any
     * @throws ApiError
     */
    public static authenticationControllerFindMe(): CancelablePromise<MyUserDto | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authentication/me',
        });
    }
    /**
     * Create entity
     * @param requestBody
     * @returns UserEntity
     * @returns any
     * @throws ApiError
     */
    public static usersControllerCreate(
        requestBody: CreateUserDto,
    ): CancelablePromise<UserEntity | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all entities
     * @param skip
     * @param take
     * @returns any
     * @returns UserEntity
     * @throws ApiError
     */
    public static usersControllerFindAll(
        skip?: number,
        take?: number,
    ): CancelablePromise<any | Array<UserEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }
    /**
     * Get one entity
     * @param id Entity ID
     * @param relations
     * @returns any
     * @returns UserEntity
     * @throws ApiError
     */
    public static usersControllerFindOne(
        id: number,
        relations?: Array<string>,
    ): CancelablePromise<any | UserEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }
    /**
     * Update an entity
     * @param id
     * @param requestBody
     * @returns any
     * @returns UserEntity
     * @throws ApiError
     */
    public static usersControllerUpdate(
        id: number,
        requestBody: UpdateUserDto,
    ): CancelablePromise<any | UserEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an entity
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static usersControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }
}

// import { PlatformLayout, PublicLayout } from "modules/common";
import { PublicLayout } from "modules/common";
import Home from "pages/Home/Home";
import Privacy from "pages/Trust/Privacy";
import Terms from "pages/Trust/Terms";
import NotFound from "pages/Utility/Error/NotFound";
import ServerError from "pages/Utility/Error/ServerError";
import { Navigate } from "react-router-dom";
import {
  AccountCreate,
  LogIn,
  LogOut,
  // PlatformProvider,
  SignUp
} from "./authentication";

const authRoutes = [
  // my
  // { path: "", element: <My /> },
];

const publicRoutes = [
  { path: "/", element: <Home /> },

  // authentication
  { path: "auth/account", element: <AccountCreate /> },
  { path: "auth/logIn", element: <LogIn /> },
  { path: "auth/logOut", element: <LogOut /> },
  { path: "auth/signUp", element: <SignUp /> },

  // trust
  { path: "trust/privacy", element: <Privacy /> },
  { path: "trust/terms", element: <Terms /> },

  // error pages
  { path: "4xx-error", element: <NotFound /> },
  { path: "5xx-error", element: <ServerError /> },

  // not found routes
  { path: "*", element: <NotFound /> }
];

const get = (isLoggedIn: boolean) => [
  {
    path: "/account/:slug",
    element: isLoggedIn ? (
      // <PlatformProvider>
      //   <PlatformLayout />
      // </PlatformProvider>
      <></>
    ) : (
      <Navigate to="auth/logIn" />
    ),
    children: []
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: publicRoutes
  }
];

export { get };

const SpinnerLoader = () => (
  <div className="spinner-chase">
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
  </div>
);

export default SpinnerLoader;

import { Outlet } from "react-router-dom";
import SharedLayout from "./SharedLayout";

const PublicLayout = () => {
  return (
    <SharedLayout>
      <Outlet />
    </SharedLayout>
  );
};

export default PublicLayout;

import { withWrapper } from "app/hooks";
// import profileImg from "assets/images/profile-img.png";
// import {
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Form,
//   Input,
//   Label,
//   Row
// } from "reactstrap";

const AccountCreate = () => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const validation = useFormik({
  //   enableReinitialize: true,

  //   initialValues: { type: AccountType.FARM, name: "" },
  //   validationSchema: Yup.object({
  //     name: validationSchema.authentication(t).accountName
  //   }),
  //   onSubmit: async (values: CreateAccountDto) => {
  //     await dispatch(createAccount(values));
  //     toast.success(
  //       t(
  //         "accountCreate.accountCreatedSuccessfully",
  //         "You have successfully created account!"
  //       )
  //     );
  //     navigate("/");
  //   }
  // });

  return (
    <></>
    // <Container className="account-pages my-5 pt-sm-5">
    //   <Row className="justify-content-center">
    //     <Col md={8} lg={6} xl={5}>
    //       <Card className="overflow-hidden">
    //         <div className="bg-primary bg-soft">
    //           <Row>
    //             <Col className="col-7">
    //               <div className="text-primary p-4">
    //                 <h5 className="text-primary">
    //                   {/* {t("accountCreate.addAccount", "Add Account")} */}
    //                 </h5>
    //               </div>
    //             </Col>
    //             <Col className="col-5 align-self-end">
    //               {/* <img src={profileImg} alt="" className="img-fluid" /> */}
    //             </Col>
    //           </Row>
    //         </div>
    //         <CardBody className="pt-0">
    //           <div className="p-2">
    //             <Form
    //               className="form-horizontal"
    //               onSubmit={(e) => {
    //                 e.preventDefault();
    //                 // validation.handleSubmit();
    //                 return false;
    //               }}
    //             >
    //               <div className="mb-3">
    //                 <Label className="form-label">
    //                   {/* {t("accountCreate.accountName", "Account Name")} */}
    //                 </Label>
    //                 <Input
    //                   name="name"
    //                   type="text"
    //                   // onChange={validation.handleChange}
    //                   // onBlur={validation.handleBlur}
    //                   // value={validation.values.name || ""}
    //                   // invalid={
    //                   //   validation.touched.name && validation.errors.name
    //                   //     ? true
    //                   //     : false
    //                   // }
    //                 />
    //                 {/* {validation.touched.name && validation.errors.name ? (
    //                   <FormFeedback type="invalid">
    //                     {validation.errors.name}
    //                   </FormFeedback>
    //                 ) : null} */}
    //               </div>

    //               <div className="mt-4">
    //                 <button
    //                   className="btn btn-primary btn-block "
    //                   type="submit"
    //                 >
    //                   {/* {t("accountCreate.addButton", "Add")} */}
    //                 </button>
    //               </div>
    //             </Form>
    //           </div>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default withWrapper(AccountCreate);

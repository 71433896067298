import { AppDispatch } from "types/store";

const load = (isLoggedIn: boolean) => async (dispatch: AppDispatch) => {
  // TODO

  if (isLoggedIn) {
    // TODO
  }
};

export { load };

import { PusherService } from "openapi";
import Pusher from "pusher-js";

const { pusherControllerAuthenticateUser, pusherControllerAuthorizeChannel } =
  PusherService;

let pusher: Pusher;

const init = () => {
  const socketUrl = process.env.PUSHER_SOCKET_URL || "";
  const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER || "";
  pusher = new Pusher(socketUrl, {
    cluster,
    userAuthentication: {
      endpoint: "",
      transport: "ajax",
      customHandler: async (payload, callback) => {
        const authData = await pusherControllerAuthenticateUser({
          ...payload,
          userData: {}
        });
        callback(null, authData);
      }
    },
    channelAuthorization: {
      endpoint: "",
      transport: "ajax",
      customHandler: async (payload, callback) => {
        const authData = await pusherControllerAuthorizeChannel(payload);
        callback(null, authData);
      }
    }
  });
};

const destroy = () => {
  pusher.disconnect();
};

const subscribe = (channelName: string) => pusher.subscribe(channelName);

export { init, destroy, subscribe };

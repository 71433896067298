import { useAppDispatch, withWrapper } from "app/hooks";
import { logOutAction } from "modules/authentication";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logOutAction());
    navigate("/");
  }, [navigate]);

  return <></>;
};

export default withWrapper(LogOut);

import { withWrapper } from "app/hooks";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  message: string;
  children: ReactNode;
}

const Error = ({ message, children }: Props) => {
  return (
    <div className="account-pages my-5 pt-5">
      {/* <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">{children}</h1>
              <h4 className="text-uppercase">{message}</h4>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary " to="/">
                  Back home
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div></div>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
};

export default withWrapper<Props>(Error);

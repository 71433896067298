import { combineReducers } from "redux";
import {
  // platformsEntitiesReducer as platformsEntities,
  authenticationViewReducer as authenticationView,
  usersEntitiesReducer as usersEntities
  // userViewReducer as userView
} from "modules/authentication";

const rootReducer = combineReducers({
  // Entities
  // platformsEntities,
  usersEntities,

  //Views
  authenticationView
  // userView
});

export default rootReducer;

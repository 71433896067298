/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChannelAuthRequestDto } from '../models/ChannelAuthRequestDto';
import type { ChannelAuthResponseDto } from '../models/ChannelAuthResponseDto';
import type { UserAuthRequestDto } from '../models/UserAuthRequestDto';
import type { UserAuthResponseDto } from '../models/UserAuthResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PusherService {
    /**
     * @param requestBody
     * @returns UserAuthResponseDto
     * @throws ApiError
     */
    public static pusherControllerAuthenticateUser(
        requestBody: UserAuthRequestDto,
    ): CancelablePromise<UserAuthResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pusher/authenticateUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ChannelAuthResponseDto
     * @throws ApiError
     */
    public static pusherControllerAuthorizeChannel(
        requestBody: ChannelAuthRequestDto,
    ): CancelablePromise<ChannelAuthResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pusher/authorizeChannel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}

import { Box, Container, CssBaseline } from "@mui/material";
import { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
  children: ReactNode;
}

const SharedLayout = ({ children }: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <Header />
      <Container maxWidth="lg">{children}</Container>
      <Footer />
    </Box>
  );
};

export default SharedLayout;

import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  AuthenticationService,
  LoginUserRequestDto,
  OpenAPI,
  SignupUserRequestDto
} from "openapi";
import { RootState } from "types/store";

interface State {
  isLoggedIn: boolean;
  accessToken?: string;
}

const {
  authenticationControllerSignup,
  authenticationControllerLogin
  // authenticationControllerChangePlatform,
  // authenticationControllerCreatePlatform
} = AuthenticationService;

// Async thunks
export const signUpAction = createAsyncThunk(
  "authentication/signUp",
  async (body: SignupUserRequestDto) =>
    await authenticationControllerSignup(body)
);

export const logInAction = createAsyncThunk(
  "authentication/logIn",
  async (body: LoginUserRequestDto) => await authenticationControllerLogin(body)
);

// export const changeAccount = createAsyncThunk(
//   "authentication/changeAccount",
//   async (accountId: number) =>
//     await authenticationControllerChangeAccount(accountId)
// );

// export const createAccount = createAsyncThunk(
//   "authentication/createAccount",
//   async (body: CreatePlatformDto) =>
//     await authenticationControllerCreateAccount(body)
// );

const setAuthenticationState = (state: State, accessToken?: string) => {
  const isLoggedIn = !!accessToken;

  state.isLoggedIn = isLoggedIn;
  state.accessToken = accessToken;

  if (isLoggedIn) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }

  OpenAPI.TOKEN = accessToken;
};

const initialState: State = {
  isLoggedIn: false
};

const accessToken = localStorage.getItem("accessToken") || undefined;
setAuthenticationState(initialState, accessToken);

// Define the slice
const slice = createSlice({
  name: "authenticationView",
  initialState,
  reducers: {
    logOutAction: (state) => setAuthenticationState(state)
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        signUpAction.fulfilled,
        logInAction.fulfilled
        // changeAccount.fulfilled,
        // createAccount.fulfilled
      ),
      (state, action) => {
        const { accessToken } = action.payload;
        setAuthenticationState(state, accessToken);
      }
    );
  }
});

export const authenticationViewReducer = slice.reducer;

// Export reducers
export const { logOutAction } = slice.actions;

// Custom selector
const selectAuthenticationView = (state: RootState) => state.authenticationView;

export const selectAccessToken = (state: RootState) =>
  selectAuthenticationView(state).accessToken;

export const selectIsLoggedIn = (state: RootState) =>
  selectAuthenticationView(state).isLoggedIn;
